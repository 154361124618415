import React, { useState } from 'react';
import './styles.css';
import { twitter_href, patreon_href, kofi_href } from '../../constants';



export const HelpButton = ({ toggleHelp }) => {
  return (
    <div className="top-right-button" onClick={toggleHelp}>
      <img src={require("./question-mark.svg").default} alt="Help icon" />
    </div>
  );
};

export const HelpModal = ({ showHelp, toggleHelp }) => {
  return (
    showHelp && (
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Need some help ?</h2>
            <button className="close-button" onClick={toggleHelp}>
              X
            </button>
          </div>
          <div className="modal-body">
            <p>Welcome to the GPTrolley website!</p>
            <br />
            <p>Here, you can ask ChatGPT its opinion on Trolley problems:
              <ol>
                <li>Enter the two things to compare.</li>
                <li>Click "Ask ChatGPT".</li>
                <li>Wait for the response from ChatGPT. (takes about 5 seconds)</li>
                <li>ChatGPT will answer which one it would save.</li>
                <li>Don't hesitate to share funny prompts with your friends and compare your answers!</li>
              </ol>
            </p>
            <br />
            <p>
              The site is free to use but each request costs me (the creator) a bit,
              so there is a data cap and any help on my <a href={patreon_href}>Patreon</a> or <a href={kofi_href}>Ko-fi</a> could
              would be greatly appreciated to keep the site running and
              make more free fun projects like this in the future.</p>
            <p>
              Also, keep in mind that ChatGPT's responses are generated using machine learning,
              and may not always provide a satisfactory or accurate response.
              <br />(This warning was written by ChatGPT when I asked it to write this help page.)</p>
            <p>If you have any issues or feedback, feel free to contact me on <a href={twitter_href}>Twitter</a>
            </p>
            <p>Thank you for using the ChatGPT Trolley Problem app!</p>
          </div>
        </div>
      </div>
    )
  );
};

export const Help = () => {
  const [showHelp, setShowHelp] = useState(false);
  function toggleHelp() {
    setShowHelp(!showHelp);
  }
  return <><HelpButton toggleHelp={toggleHelp} />
    <HelpModal showHelp={showHelp} toggleHelp={toggleHelp} /></>
}


export default Help