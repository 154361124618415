// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` /* make adsbygoogle float at the bottom of the page */
 .adsbygoogle {
     position: fixed;
     /* or 'absolute' if you want it relative to a positioned parent */
     bottom: 0;

     /* Anchors it to the left side of the screen */
     width: 50%;
     max-width: 728px;
     /* Adjust width as needed */
     height: 90px;
     /* Adjust height as needed */
     z-index: -1000;
     /* Ensures it stays on top of other content */
     /* background-color: #f1f1f1; */
 }`, "",{"version":3,"sources":["webpack://./src/components/Ad/styles.css"],"names":[],"mappings":"CAAC,qDAAqD;CACrD;KACI,eAAe;KACf,iEAAiE;KACjE,SAAS;;KAET,8CAA8C;KAC9C,UAAU;KACV,gBAAgB;KAChB,2BAA2B;KAC3B,YAAY;KACZ,4BAA4B;KAC5B,cAAc;KACd,6CAA6C;KAC7C,+BAA+B;CACnC","sourcesContent":[" /* make adsbygoogle float at the bottom of the page */\n .adsbygoogle {\n     position: fixed;\n     /* or 'absolute' if you want it relative to a positioned parent */\n     bottom: 0;\n\n     /* Anchors it to the left side of the screen */\n     width: 50%;\n     max-width: 728px;\n     /* Adjust width as needed */\n     height: 90px;\n     /* Adjust height as needed */\n     z-index: -1000;\n     /* Ensures it stays on top of other content */\n     /* background-color: #f1f1f1; */\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
