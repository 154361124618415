// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.word {
    opacity: 0;
    overflow: hidden;
    display: inline-block;
    max-width: 0;
    white-space: nowrap;
    vertical-align: bottom;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


@keyframes fadeIn {
    0% {
      opacity: 0;
      max-width: 0;
    }
    1% {
      max-width: 100%;
    }
    100% {
      opacity: 1;
      max-width: 100%;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Prompt/TextReveal.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;IACZ,mBAAmB;IACnB,sBAAsB;AAC1B;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,UAAU;EACZ;AACF;;;AAGA;IACI;MACE,UAAU;MACV,YAAY;IACd;IACA;MACE,eAAe;IACjB;IACA;MACE,UAAU;MACV,eAAe;IACjB;EACF","sourcesContent":[".word {\n    opacity: 0;\n    overflow: hidden;\n    display: inline-block;\n    max-width: 0;\n    white-space: nowrap;\n    vertical-align: bottom;\n}\n\n@keyframes fadeIn {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n\n@keyframes fadeIn {\n    0% {\n      opacity: 0;\n      max-width: 0;\n    }\n    1% {\n      max-width: 100%;\n    }\n    100% {\n      opacity: 1;\n      max-width: 100%;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
