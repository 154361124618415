// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  padding: 10px;
  position: fixed;
  bottom: 0;
  right: 0;
  border-top-left-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.footer-left p {
  margin: 0;
  font-size: 14px;
}

.footer-right img {
  height: 20px;
  margin-left: 5px;
  margin-right: 5px;
}

.footer-right img:hover {
  opacity: 0.7;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,eAAe;EACf,SAAS;EACT,QAAQ;EACR,4BAA4B;EAC5B,yCAAyC;AAC3C;;AAEA;EACE,SAAS;EACT,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,eAAe;AACjB","sourcesContent":[".footer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  background-color: #FFFFFF;\n  padding: 10px;\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  border-top-left-radius: 10px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n.footer-left p {\n  margin: 0;\n  font-size: 14px;\n}\n\n.footer-right img {\n  height: 20px;\n  margin-left: 5px;\n  margin-right: 5px;\n}\n\n.footer-right img:hover {\n  opacity: 0.7;\n  cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
