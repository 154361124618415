import { useEffect } from 'react';
import './App.css';
import Help from './components/Help';
import Stats from './components/Stats';
import Footer from './components/Footer';
import Prompt from './components/Prompt';
import Ad from './components/Ad';

import { Adsense } from '@ctrl/react-adsense';


function App() {
  // const loadAdScript = () => {
  //   const script = document.createElement('script');
  //   script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js`;
  //   script.async = true;
  //   document.head.appendChild(script);
  // };
  // useEffect(() => {
  //   loadAdScript();
  // }, []);

  return (
    <div className="App">
      <div className="top-right-container">
        <Help />
        {false && <Stats />}
      </div>
      <Prompt />
      <Footer />
      <Ad />
    </div>
  );
}

export default App;
