import './styles.css';
import { twitter_href, patreon_href, kofi_href } from '../../constants';




const ErrorNoConnectionToServer = () => {
  return (
    <div>
      Oops! It seems like the connection to the server is currently unavailable. Don't worry, I'm on it! In the meantime, feel free to check my{" "}
      <a href={twitter_href} target="_blank" rel="noopener noreferrer">
        Twitter
      </a>{" "}
      for updates and some interesting tidbits.
    </div>
  );
}
const ErrorOpenAINoFunds = () => {
  return (
    <div>
      Oh no! I'm currently out of funds to process OpenAI requests. I already spent $120 on OpenAI requests to keep this site running! If you'd like to help, please consider supporting me on &nbsp;
      <a href={patreon_href} target="_blank" rel="noopener noreferrer">
         Patreon 
      </a>{" "} or &nbsp;
      <a href={kofi_href} target="_blank" rel="noopener noreferrer">
        Ko-fi 
      </a>{" "} 
      – every little bit helps to keep this server running and to fund my future projects! For updates on when I've topped up the OpenAI API funds again, follow me on{" "}
      <a href={twitter_href} target="_blank" rel="noopener noreferrer">
        Twitter
      </a>.
    </div>
  );
}




export const ErrorModal = ({ error, setError }) => {
  function closeErrorWindow() {
    setError(0);
  };
  return (
    error ? (
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2>
              {error === 500 && "Error 500 : Internal Server error"}
              {error === 503 && "Error 503 : Service unavaliable"}
            </h2>
            <button className="close-button" onClick={closeErrorWindow}>
              X
            </button>
          </div>
          <div className="modal-body">
            {error === 500 && <ErrorNoConnectionToServer />}
            {error === 503 && <ErrorOpenAINoFunds />}
          </div>
        </div>
      </div>
    )
  :'');
};



export default ErrorModal