export const examples = [
    ["A cat", "A dog"],
    ["Paris", "London"],
    ["Michael Jackson", "Prince"],
    ["Apple", "Samsung"],
    ["Democracy", "Republic"],
    ["Coffee", "Tea"],
    ["Sunrise", "Sunset"],
    ["Mozart", "Beethoven"],
    ["The Beatles", "The Rolling Stones"],
    ["Soccer", "Basketball"],
    ["Mount Everest", "K2"],
    ["Albert Einstein", "Isaac Newton"],
    ["Pasta", "Rice"],
    ["Summer", "Winter"],
    ["Introvert", "Extrovert"],
    ["New York City", "Tokyo"],
    ["Jane Austen", "Charles Dickens"],
    ["Lion", "Tiger"],
    ["Mathematics", "Physics"],
    ["Venus", "Mars"],
    ["Oxygen", "Carbon"],
    ["Penguin", "Polar Bear"],
    ["Shakespeare", "Molière"],
    ["Biology", "Chemistry"],
    ["Netflix", "Hulu"],
    ["Eiffel Tower", "Statue of Liberty"],
    ["Hiking", "Biking"],
    ["Sushi", "Pizza"],
    ["Elephant", "Rhino"],
    ["Harry Potter", "Lord of the Rings"],
    ["Gandhi", "Nelson Mandela"],
    ["Amazon", "Alibaba"],
    ["Electric car", "Hybrid car"],
    ["Great Wall of China", "Grand Canyon"],
    ["Yoga", "Pilates"],
    ["Picasso", "Van Gogh"],
    ["Whale", "Dolphin"],
    ["Rainforest", "Coral Reef"],
    ["Spaghetti", "Fettuccine"],
    ["North Pole", "South Pole"],
    ["Marsupials", "Placental mammals"],
    ["Freud", "Jung"],
    ["Star Wars", "Star Trek"],
    ["Fossil fuels", "Renewable energy"],
    ["Comedy", "Tragedy"],
    ["Basketball", "Baseball"],
    ["Olympics", "Paralympics"],
    ["Taj Mahal", "Pyramids of Giza"],
    ["Veganism", "Vegetarianism"],
    ["Ballet", "Contemporary dance"],
    ["Instagram", "Snapchat"],
    ["Audiobooks", "E-books"],
    ["Guitar", "Piano"],
    ["Apollo 11", "Challenger Disaster"],
    ["Kangaroo", "Koala"],
    ["Fishing", "Hunting"],
    ["Opera", "Symphony"],
    ["Frida Kahlo", "Georgia O'Keeffe"],
    ["Butterfly", "Hummingbird"],
    ["Boxing", "Martial Arts"],
    ["Tesla", "Ford"],
    ["Bee", "Ant"],
    ["Chinese", "Japanese"],
    ["Mount Rushmore", "Stonehenge"],
    ["Jazz", "Blues"],
    ["Skateboarding", "Snowboarding"],
    ["Facebook", "Twitter"],
    ["Galileo", "Copernicus"],
    ["Burger", "Sandwich"],
    ["Ancient Rome", "Ancient Greece"],
    ["Online shopping", "In-store shopping"],
    ["Coke", "Pepsi"],
    ["FIFA World Cup", "Olympic Games"],
    ["Xbox", "PlayStation"],
    ["Monopoly", "Risk"],
    ["Tomatoes", "Bell peppers"],
    ["Titanic", "Avatar"],
    ["Cycling", "Running"],
    ["Homer", "Virgil"],
    ["Sphinx", "Manticore"],
    ["Stephen King", "J.K. Rowling"],
    ["Swimming", "Diving"],
    ["Niagara Falls", "Victoria Falls"],
    ["Laptop", "Desktop"],
    ["Eagle", "Hawk"],
    ["Tornado", "Hurricane"],
    ["Carnivore", "Herbivore"],
    ["Romeo and Juliet", "Pride and Prejudice"],
    ["Scuba diving", "Snorkeling"],
    ["Acoustic guitar", "Electric guitar"]
]


/*
Give me a list of 30 couple of "things" (people, concepts, animals) that are comparable, but different and "worth" about the same. Format them like this :
    ["A cat", "A dog"],
    ["Paris", "London"],
    ["Michael Jackson", "Prince"],
*/