// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shareButton {
  background-color: #1DA1F200;
  border: none;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  position: absolute;
  margin-left: 10px;
}

.shareButton:hover {
  background-color: #1DA1F2;
}

.shareButton img {
  height: calc(1.5rem * var(--fsc));
  color: white;
}

.shareButton:hover img {
  filter: invert(100%);
}

`, "",{"version":3,"sources":["webpack://./src/components/ShareButton/styles.css"],"names":[],"mappings":"AAAA;EACE,2BAA2B;EAC3B,YAAY;EACZ,YAAY;EACZ,oBAAoB;EACpB,mBAAmB;EACnB,uBAAuB;EACvB,eAAe;EACf,kBAAkB;EAClB,sCAAsC;EACtC,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,iCAAiC;EACjC,YAAY;AACd;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".shareButton {\n  background-color: #1DA1F200;\n  border: none;\n  color: white;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  cursor: pointer;\n  border-radius: 5px;\n  transition: background-color 0.3s ease;\n  position: absolute;\n  margin-left: 10px;\n}\n\n.shareButton:hover {\n  background-color: #1DA1F2;\n}\n\n.shareButton img {\n  height: calc(1.5rem * var(--fsc));\n  color: white;\n}\n\n.shareButton:hover img {\n  filter: invert(100%);\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
