import React, { useState, useEffect } from 'react';
import { useFetch } from "../../FetchContext";
import { useNavigate, useLocation } from 'react-router-dom';
import './styles.css';
import "./TextReveal.css";
import { examples } from './examples';
import ShareButton from '../ShareButton';




const TextReveal = ({ text, delay }) => {
  const [words, setWords] = useState([]);
  const [animationKey, setAnimationKey] = useState(0);

  useEffect(() => {
    const splitWords = text.split(" ").map((word, index) => (
      <span
        key={index}
        className="word"
        style={{
          animation: `fadeIn 0.3s ${delay * index}ms forwards`,
        }}
      >
        {word}&nbsp;
      </span>
    ));
    setWords(splitWords);
    setAnimationKey((prevKey) => prevKey + 1);
  }, [text, delay]);

  return <div key={animationKey} className="output" >{words}</div>;
};

const updateFontSize = (container) => {
  const minRatio = 0.4;
  const maxRatio = 0.9;
  const step = 0.9;
  const ratio = container.scrollHeight / document.documentElement.clientHeight;

  const fsc = document.documentElement.style.getPropertyValue("--fsc") - 0;
  if (!fsc) {
    document.documentElement.style.setProperty("--fsc", 1);
    return;
  }
  console.log("resizing prompt: fsc=", fsc, ", ratio=", ratio);

  var newfsc = fsc;

  if (ratio > maxRatio) {
    newfsc = fsc * step;
  }
  if (ratio < minRatio) {
    newfsc = fsc / step;
  }
  if (newfsc > 1) newfsc = 1;
  document.documentElement.style.setProperty("--fsc", newfsc);
};



const Prompt = () => {
  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [output, setOutput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { safeFetch } = useFetch();

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const left = queryParams.get('left');
    const right = queryParams.get('right');

    if (left) setInput1(left);
    if (right) setInput2(right);
  }, [location]);


  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    navigate(`/?left=${input1}&right=${input2}`, { replace: true });
    const data = await safeFetch("generate_output", { input1, input2 });
    if (data.error)
      return;
    setIsLoading(false);
    setOutput(data);
  };


  //resize
  useEffect(() => {
    const container = document.querySelector(".container");
    if (!container) return;

    updateFontSize(container);

    const resizeObserver = new ResizeObserver(() => {
      updateFontSize(container);
    });

    resizeObserver.observe(container);

    return () => {
      resizeObserver.disconnect();
    };
  }, []);


  //gray placeholder text
  const [placeholders, setPlaceholders] = useState(['', '']);
  useEffect(() => {
    setPlaceholders(examples[Math.floor(Math.random() * examples.length)])
  }, []);


  return (
    <div className={`container ${isLoading ? 'loading' : ''}`} >
      <div className="logo">GPTrolley.com</div>
      <h1>There is a runaway trolley barreling down the railway tracks...</h1>
      <form onSubmit={handleSubmit}>
        <div className="inputContainer">
          <input
            type="text"
            className="input"
            placeholder={placeholders[0]}
            value={input1}
            onChange={(e) => setInput1(e.target.value)}
          />
          <input
            type="text"
            className="input"
            placeholder={placeholders[1]}
            value={input2}
            onChange={(e) => setInput2(e.target.value)}
          />
        </div>
        <div className="button-container">
          <button type="submit" disabled={isLoading || !input1 || !input2}>
            Ask ChatGPT
          </button>
          {output && <ShareButton />}
        </div>
      </form>
      {output && <TextReveal text={output} delay={50} />}
    </div >
  );
}
export
  const StaticPrompt = ({ input1, input2, output }) => {
    return (
      <div className="container" >
        <div className="logo">GPTrolley.com</div>
        <h1>There is a runaway trolley barreling down the railway tracks...</h1>
        <div className="inputContainer">
          <input
            type="text"
            className="input"
            placeholder="Track 1"
            value={input1}
            readOnly
          />
          <input
            type="text"
            className="input"
            placeholder="Track 2"
            value={input2}
            readOnly
          />
        </div>
        <div className="output">{output}</div>
      </div >
    );
  }


export default Prompt;
