import React from 'react';
import { useLocation } from 'react-router-dom';
//import { captureScreenshot } from './captureScreenshot';
import './styles.css';

const ShareButton = ({ input1, input2, output }) => {


    const location = useLocation();

    const share = async () => {

        // simply opens an alert with the text to share
        const text = `You really thought Twitter works these days?`;
        alert(text);

        // //    const screenshotDataURL = await captureScreenshot(promptRef.current);
        // const queryParams = new URLSearchParams(location.search);
        // const left = queryParams.get('left');
        // const right = queryParams.get('right');

        // const text = `I asked ChatGPT to choose between ${left} and ${right}, with #GPTrolley !\n\nTry it here:\n`;

        // // Use the appropriate API for sharing the content on the desired platform
        // // For example, use the Twitter Web Intent API for sharing on Twitter
        // const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(window.location.href)}`;
        // window.open(url, '_blank');
    };
    /*0-width spacehack, nice*/
    return (
        <button type="button" onClick={share} className="shareButton">
            <img src={require("./twitter-icon.svg").default} alt="Twitter icon" />
        </button>
    );
};

export default ShareButton;
