import './styles.css';
import React, { useEffect } from 'react';
import { Adsense } from '@ctrl/react-adsense';

const loadAdScript = () => {
    const script = document.createElement('script');
    script.src = `https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js`;
    script.async = true;
    document.head.appendChild(script);
};



const Ad = () => {
    useEffect(() => {
        loadAdScript();
    }, []);

    return <Adsense
        client="ca-pub-2620096876082429"
        slot="5116718707" />;
};

export default Ad;
