import React, { createContext, useContext, useState } from "react";
import ErrorModal from "./components/Error";


const FetchContext = createContext();

export const useFetch = () => {
  return useContext(FetchContext);
};

export const FetchProvider = ({ children }) => {

    const [error, setError] = useState(0);

  const safeFetch = async (endpoint, params) => {
    const queryString = new URLSearchParams(params).toString();
    const url = `/${endpoint}?${queryString}`;

    try {
      console.log("fetching :", url);

      const response = await fetch(url);

      if(response.status == 500)
      {
        setError(500); return;
      }
      if(response.status == 503)
      {
        setError(503); return;
      }
      const data = await response.json();
      console.log(`${endpoint} received from server:`, data);
      if(data.error) {
        console.log("not enough funds!");
        setError(503); 
      }
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(500);
    }
  };

  return (
    <FetchContext.Provider value={{ safeFetch }}>
        <ErrorModal error={error} setError={setError}/>
      {children}
    </FetchContext.Provider>
  );
};