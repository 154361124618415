import React, { useState, useEffect } from 'react';
import './styles.css';




export const StatsButton = ({ toggleStats }) => {
  return (
    <div className="top-right-button" onClick={toggleStats}>
      <img src={require("./stats.svg").default} alt="Stats icon" />
    </div>
  );
};

export const StatsModal = ({ showStats, toggleStats, statsData }) => {
  return (
    showStats && (
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2>Need some stats ?</h2>
            <button className="close-button" onClick={toggleStats}>
              X
            </button>
          </div>
          <div className="modal-body">
            {statsData && (
              <div>
                <p>Total number of prompts: {statsData.prompts_total}</p>
                <p>Total prompts today: {statsData.prompts_today}</p>
                <p>Average response time: {Math.round(statsData.avg_response_time * 10) / 10}s</p>
                <p>Current number of users: {statsData.users_current}</p>
                <p>Max number of users: {statsData.users_max}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
};


export const Stats = () => {


  const [showStats, setShowStats] = useState(false);
  const [statsData, setStatsData] = useState(null);



  useEffect(()=> async () => {
    try {
      console.log("fetching :", `/get_stats`);

      const response = await fetch(
        "/get_stats"
      );
      const data = await response.json();
      console.log("/get_stats received from server:", data);
      setStatsData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [])

  function toggleStats() {
    setShowStats(!showStats);
  };

  return <div>
    <StatsButton toggleStats={toggleStats} />
    <StatsModal showStats={showStats} toggleStats={toggleStats} statsData={statsData} />
  </div>
}


export default Stats